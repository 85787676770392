import {Typography} from 'antd'
import {Helmet} from 'react-helmet-async'

const SocialPage = () => {
    const {Title} = Typography
    return (
        <>      
            <Helmet>
                <title>Stalk - Zack Amiton</title>
            </Helmet>

            <Title level={2}>i am embarrassingly online.</Title>
            
            <Title level={3}>
                feel free to stalk me anywhere* cool people hang out
            </Title>
            <Title level={4}>
                <ul>
                    <li><a href="https://github.com/Nathansbud">github</a></li>
                    <li><a href="https://linkedin.com/in/zamiton/">linkedin</a></li>
                    <li><a href="https://instagram.com/nathansbud">instagram</a></li>
                    <li><a href="https://boardgamegeek.com/collection/user/Nathansbud">boardgamegeek</a></li>
                    <li><a href="https://last.fm/user/Nathansbud">last.fm</a></li>
                    <li><a href="https://open.spotify.com/user/6rcq1j21davq3yhbk1t0l5xnt">spotify</a></li>
                    <li><a href="https://letterboxd.com/Nathansbud/">letterboxd</a></li>
                    <li><a href="https://reddit.com/u/Nathansbud/">reddit</a></li>
                </ul>
            </Title>

            <Title level={4}>
                and send all fan-mail to <a href="mailto:dork@zamiton.com">dork@zamiton.com</a>
            </Title>

            <b>*please don't stalk me in-person unless you're, like, super rad</b>
        </>
    )
}

export default SocialPage;
