import {Typography} from 'antd';
import {Helmet} from 'react-helmet-async'

const MuzackPage = () => {
    const {Title, Paragraph} = Typography;
    return (
        <>      
            <Helmet>
                <title>Muzack - Zack Amiton</title>
            </Helmet>
            <Title level={2}>muzack!</Title>
            <Paragraph>
            i am a minor music nerd (see: my <a href="https://www.last.fm/user/Nathansbud">last.fm</a>, muzack <a href="https://open.spotify.com/playlist/2bQJC2lUa4pXkAt2qQejlx?si=23ebad32d7124457">spotify playlist</a>, the speaker constantly blaring in my room, and <a href="https://github.com/Nathansbud/TuningFork">various other goodies</a>)
            </Paragraph>
            <Paragraph>
            <b>in 2022</b>: i started making a more active effort to keep track of the music i listened to, which culminated in <a href="https://zummary.wordpress.com/">zummary</a>, an end of year recap which i plan to do each year moving forwards!
            </Paragraph>
            <Paragraph>
            <b>in 2023</b>: i plan to listen to an album each day, aiming for <b>365</b> new albums for the year!
            </Paragraph>
            
            <Title level={3} className="primary">additional joy sparkers:</Title>
            <ul>
                <li><b style={{
                    color: "#BE010B"
                }}>zack.fm</b> (fka artistic endeavor to make my wall maybe not look like a white void)
                </li>
            </ul>
            <Paragraph>
                the <b>very, very, very talented</b> <a href="https://www.instagram.com/elise.petit8/">elise petit</a> took on the daunting task of beautifying my bare dorm wall, creating <b>unique works of art</b> featuring a lyric from each of my most played (ish) songs every month since starting at brown!
            </Paragraph>
            <Paragraph>
                i will hold off on showing off the project until i can do the art the justice it deserves (aka hang it all up), but in terms of <a href="https://open.spotify.com/playlist/7fJsDAvRtNZiPFq4TiZDhL?si=8d8c90f05e9d4bc9">the represented tunes...</a>
            </Paragraph>
            <ul className='sublist'>
                <li><b className="secondary">aug 21</b>: <b>night shift</b> (<i>you got a 9-to-5, so i'll take the night shift</i>)</li>
                <li><b className="secondary">sep 21</b>: <b>saliva</b> (<i>great balls of fire / guess who just crawled out the muck and mire</i>)</li>
                <li><b className="secondary">oct 21</b>: <b>frontier psychiatrist</b> (<i>psychosomatic / that boy needs therapy</i>)</li>
                <li><b className="secondary">nov 21</b>: <b>cut me</b> (<i>might not be healthy for me, but seemingly i need / what cuts me, cuts me, cuts me, cut me, cut me, cut me</i>)</li>
                <li><b className="secondary">dec 21</b>: <b>guerrilla</b> (<i>show up to the party / it's guerrilla / with an itty bitty chance of having a good time</i>)</li>
                <li><b className="secondary">jan 22</b>: <b>hazard duty pay</b> (<i>glock with a switch, turn a vegan to spinach</i>)</li>
                <li><b className="secondary">feb 22</b>: <b>pedestrian at best</b> (<i>put me on a pedestal and i'll only disappoint you</i>)</li>
                <li><b className="secondary">mar 22</b>: <b>gangsta</b> (<i>what's a boy to do if he'll never be a gangsta?</i>)</li>
                <li><b className="secondary">apr 22</b>: <b>1937 skate park</b> (<i>i didn't want you to hear / that shake in my voice / my pain is my own</i>)</li>
                <li><b className="secondary">may 22</b>: <b>chaos space marine</b> (<i>in time, you will find / these things take up space inside your mind</i>)</li>
                <li><b className="secondary">jun 22</b>: <b>bedouin dress</b> (<i>if to borrow is to take and not return / i have borrowed all my lonesome life</i>)</li>
                <li><b className="secondary">jul 22</b>: <b>shut up kiss me</b> (<i>shut up, kiss me, hold me tight</i>)</li>
                <li><b className="secondary">aug 22</b>: <b>water fountain</b> (<i>no water in the water fountain / no side on the sidewalk</i>)</li>
                <li><b className="secondary">sep 22</b>: <b>summertime clothes</b> (<i>when the sun goes down, we'll go out again</i>)</li>
                <li><b className="secondary">oct 22</b>: <b>pomeranian spinster</b> (<i>i don't care if you write me / please don't invite me</i>)</li>
                <li><b className="secondary">nov 22</b>: <b>percolater</b> (<i>i'm gonna die in a getaway car / i haven't tried, but it sounds too hard</i>)</li>
                <li><b className="secondary">dec 22</b>: <b>when you know you know</b> (<i>you and me aglow in the cosmos</i>)</li>
            </ul>
        </>
    )
}

export default MuzackPage;
