import {
    Typography
} from 'antd'
import {Helmet} from 'react-helmet-async'

const HomePage = () => {
    const {Title} = Typography
    return (
        <>      
            <Helmet>
                <title>Home - Zack Amiton</title>
            </Helmet>
            <Title level={2}>hi, i'm zack.</Title>
            <Title level={3}>brown '25.</Title>
            <Title level={3}>formerly: software @ <a href="https://www.duolingo.com/">duolingo</a>, <a href="https://www.southwest.com/">southwest</a>, <a href="https://www.getmabel.com/">mabel</a>, <a href="https://www.ics.com/">ics</a>.</Title>
        </>
    )
}

export default HomePage;
