import { Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

const Navbar = () => {
    const navigate = useNavigate()
    const { Item } = Menu;

    const { pathname: key } = useLocation();
    const keyParts = key.slice(1).split("/");
    return (
        <Menu mode="horizontal" style={{fontSize: '225%'}} selectedKeys={[keyParts[0] || 'home', ...keyParts.slice(1)]}>
            <Item key='home' onClick={() => navigate('/')}>
                home
            </Item>
            <Item key='projects' onClick={() => navigate('/projects')}>
                projects
            </Item>
            <Menu.SubMenu key="misc" title="misc" onTitleClick={() => navigate('/misc')}>
                <Item key='muzack' onClick={() => navigate('/muzack')}>
                    muzack!
                </Item>
                <Item key='keyboards' onClick={() => navigate('/keyboards')}>
                    keyboards!
                </Item>
            </Menu.SubMenu>
            <Item key='stalk' onClick={() => navigate('/stalk')}>
                stalk
            </Item>
            <Item key='resume'>
                <a href="/resume.pdf" target="_blank" rel="noopener noreferrer">
                    resume
                </a>
            </Item>
        </Menu>
    )
}

export default Navbar;
