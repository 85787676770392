import {Fragment} from 'react'
import {
    Image,
    Typography, 
    Row,
    Col,
    Carousel,
    ImageProps
} from 'antd';
import {Helmet} from 'react-helmet-async'

import RPGSentences from '../media/rpg/sentences.png'
import JeopardizerStart from '../media/jeopardizer/start.png'
import JeopadizerConsole from '../media/jeopardizer/console.png'
import JeopadizerBoard from '../media/jeopardizer/board.png'
import MediaLog from '../media/medialog.png'
import { ReactElement } from 'react';

interface ProjectEntryProps {
    title: string;
    src: string;
    body?: (string | ReactElement<any, any>)[];
    subtitle?: string;
    subsrc?: string;
    images?: ImageProps[];
}

const ProjectEntry: React.FC<ProjectEntryProps> = ({
    title, src, body, subtitle, subsrc, images
}) => {
    const {Title, Text} = Typography;
    return (
        <div style={{padding: 16}}>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Title level={3}>
                        <a href={src}>{title}</a>{' '}
                        {subsrc ? 
                            (<a href={subsrc}>({subtitle ?? 'source'})</a>) :
                            (subtitle ? <span>({subtitle})</span> : '')
                        }
                    </Title>
                    <Text style={{fontSize: '110%'}}>
                        {body ? body.map((l, i) => <Fragment key={i}><span>{l}</span><br/></Fragment>) : ''}
                    </Text> 
                </Col>
                <Col span={8}>
                    <Carousel dotPosition='top'>
                        {images ? images.map((im, i) => <Image key={i} src={im.src} alt={im.alt} placeholder={im.placeholder}/>) : ''}
                    </Carousel>
                </Col>
            </Row>
        </div>
    )
}

const ProjectPage = () => {
    const {Title} = Typography;
    return (
        <>
            <Helmet>
                <title>Projects - Zack Amiton</title>
            </Helmet>
            <Title level={2}>some silly things i've made.</Title>
            <ProjectEntry 
                title='jeopardizer'
                src='https://nathansbud.github.io/Jeopardizer'
                subsrc='https://github.com/Nathansbud/Jeopardizer'
                images={[
                    {src: JeopardizerStart},
                    {src: JeopadizerBoard},
                    {src: JeopadizerConsole}
                ]}
                body={[
                    'jeopardy, for the folks at home.',
                    'designed for 2 displays, and 2+ players.',
                    'supports custom categories and other fun stuff.',
                    'shoutout j-archive, they rule.'
                ]}
            />
            <ProjectEntry
                title="rpg: report generator"
                src="https://github.com/Nathansbud/ReportCardGenerator"
                body={[
                    "bare-bones report card automation app for teachers.",
                    "syncs to both excel or sheets.",
                ]}
                images={[
                    {src: RPGSentences},
                ]}
            />
            <ProjectEntry 
                title="media log"
                src="https://media.zamiton.com"
                body={[
                    "running log of media i consume."
                ]}
                images={[
                    {src: MediaLog}
                ]}
            />
            <ProjectEntry
                title="more nonsense on github"
                src="https://github.com/Nathansbud"
                body={[
                    (<a href="https://github.com/Nathansbud/TuningFork">magical musical messiness.</a>),
                    (<a href="https://github.com/Nathansbud/BoredGamer">boardgamegeek utilities.</a>),
                    "...and more!"
                ]}
            />
        </>
    )
}

export default ProjectPage;
