import { Typography } from "antd";
import { Helmet } from "react-helmet-async";

const MiscPage = () => {
    const {Title, Paragraph} = Typography;
    return (
        <>      
            <Helmet>
                <title>Miscellaneous - Zack Amiton</title>
            </Helmet>
            <Title level={2}>miscellany!</Title>
            <Paragraph>
            i need a place to put thoughts which fall somewhere on the spectrum between <a href="https://twitter.com/maybenathansbud">twitter</a> and my diary, so this site has to foot the bill
            </Paragraph>
            <ul>
                <b><li><a href="/muzack">muzack!</a></li></b>
                <b><li><a href="/keyboards">keyboards!</a></li></b>
                <li className="todo">travel!</li>
            </ul>
        </>     
    )
}

export default MiscPage;
