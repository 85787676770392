import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import {Layout} from 'antd'

import { HelmetProvider } from 'react-helmet-async'
import useWindowDimensions from './hooks/useWindowDimensions'

import Navbar from './components/Navbar'

import 'react-simple-keyboard/build/css/index.css';
import './Styles.less'

import HomePage from './pages/HomePage'
import ProjectPage from './pages/ProjectPage'
import SocialPage from './pages/SocialPage'
import MuzackPage from './pages/MuzackPage'
import MiscPage from './pages/MiscPage'
import KeyboardPage from './pages/KeyboardPage'

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/projects" element={<ProjectPage/>}/>
            <Route path='/stalk' element={<SocialPage/>}/>
            <Route path='/misc' element={<MiscPage/>}/>
            <Route path='/muzack' element={<MuzackPage/>}/>
            <Route path='/keyboards' element={<KeyboardPage/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}

const AppHome = () => {
    const { height } = useWindowDimensions()
    return (
        <Router>
            <Layout>
                <Navbar/>
                <Layout.Content
                    style={{
                        padding: '24px 24px 0px',
                        minHeight: height - 50
                    }}
                >
                    <div 
                        className="layout-page-background"
                        style={{ padding: 24}}
                    >
                        <AppRoutes/>
                    </div>
                    
                </Layout.Content>
            </Layout>
        </Router>
    )
}

const App = () => {
    return (
      <HelmetProvider> 
        <AppHome/>
      </HelmetProvider>
    )
}

export default App;
