import { Checkbox, Typography } from "antd";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const KeyboardPage = () => {
    const {Title, Paragraph} = Typography;
    
    const [isCute, setCute] = useState<Boolean>(false);
    const [isSuperCute, setSuperCute] = useState<Boolean>(false);

    const KeyboardProps = {
        layout: {
            default: [
                "` 1 2 3 4 5 6 7 8 9 0 - = {delete}",
                "{tab} q w e r t y u i o p [ ] \\",
                "{lock} a s d f g h j k l ; ' {enter}",
                "{shift} z x c v b n m , . / {shift}",
                "{ctrl} {option} {cmd} {space} {cmd} {option}",
            ],
            shift: [
                "~ ! @ # $ % ^ & * ( ) _ + {delete}",
                "{tab} Q W E R T Y U I O P { } |",
                "{lock} A S D F G H J K L : \" {enter}",
                "{shift} Z X C V B N M < > ? {shift}",
                "{ctrl} {option} {cmd} {space} {cmd} {option}"
            ],
            option: [
                "` ¡ ™ £ ¢ ∞ § ¶ • ª º – ≠ {delete}",
                "{tab} œ ∑ ´ ® † ¥ ¨ ˆ ø π “ ‘ «",
                "{lock} å ß ∂ ƒ © ˙ ∆ ˚ ¬ … æ {enter}",
                "{shift} Ω ≈ ç √ ∫ ˜ µ ≤ ≥ ÷ {shift}",
                "{ctrl} {option} {cmd} {space} {cmd} {option}"
            ],
            optionShift: [
                "` ⁄ € ‹ › ﬁ ﬂ ‡ ° · ‚ — ± {delete}",
                "{tab} Œ „ ´ ‰ ˇ Á ¨ ˆ Ø ∏ ” ’ »",
                "{lock} Å Í Î Ï ˝ Ó Ô  Ò Ú Æ {enter}",
                "{shift} ¸ ˛ Ç ◊ ı ˜ Â ¯ ˘ ¿ {shift}",
                "{ctrl} {option} {cmd} {space} {cmd} {option}"
            ],
            cuteOption: [
                "` ¡ ™ £ ¢ ∞ ₹ æ • ° – ≠ {delete}",
                "{tab} :thinking: ∑ ´ ρ θ © ¨ ˆ ø π → ↑ «",
                "{lock} α σ ∂ ® γ ∫ ∆ ω λ ¬ :weary: {enter}",
                "{shift} Ω ≈ ∴ √ β ˜ µ ≤ ≥ ÷ {shift}",
                "{ctrl} {option} {cmd} {space} {cmd} {option}"
            ],
            cuteOptionShift: [
                "\u005B•-•\u005D \\[•-•]/ \\[•-•]/ ╰[•-•]╯ ⸌[•-•]⸍ ⸜[•-•]⸝  Æ  ‹ › — ± {delete}",
                "{tab} .[•-•]7 └[•-•]┘ /[•-•]\\ ⎝[•-•]⎠   .[•-•]ፓ    ← ↓ »",
                "{lock} ⤚[•-•]⤙ ⟅[•-•]⟆ ~[•-•]~ ╭[•-•]╮  ε      {enter}",
                "{shift} [''•-•] [•-•''] ୧[•-•]୨ [•⥐•] ⸐[•-•]⸑    ゴゴゴゴ ¿ {shift}",
                "{ctrl} {option} {cmd} {space} {cmd} {option}"
            ],
            superCuteOption: [

            ],
            superCuteOptionShift: [
                
            ]

        },
        mergeDisplay: true,
        display: {
            "{ctrl}": "control",
            "{option}": "option",
            "{cmd}": "command",
            "[•-•]": "[•-•]"
        }
    }

    return (
        <>
            <Helmet>
                <title>Keyboards - Zack Amiton</title>
            </Helmet>
            <Title level={2}>
                keyboards!
            </Title>
            <Paragraph>
                i am very into keyboards, but not the kind that go clickity-clack: <i>digital</i> keyboards
            </Paragraph>
            <Paragraph>
                growing up, i had an extended "not like the other girls" era (unclear whether it ever ended) where i rejected emojis entirely and created my own set of custom emotes to use.
            </Paragraph>
            <Paragraph>
                naturally, this was widely regarded as an incredibly cool personality trait by the masses ୧[•-•]୨
            </Paragraph>
            <Paragraph>
                copying my ever-growing cast of characters from a long note document grew annoying quick; <b className="primary">enter <a href="https://software.sil.org/ukelele/">ukelele</a></b>, an awesome tool to make <b>custom input methods</b>
            </Paragraph>
            <Paragraph>
                now, where to put our keys? let's examine our keyboard.
            </Paragraph>
            <Keyboard layoutName="default" {...KeyboardProps}/>
            <br/>
            <Paragraph>
                we <i>could</i> replace all our letters with superior strings, but i quite like to type words └[•-•]┘
            </Paragraph>
            <Paragraph>
                instead, we can take advantage of <b>modifier keys</b> (shift, control, option) to see if we can nest any extra keyboards without making our daily typing too cumbersome
            </Paragraph>
            <Paragraph>
                <b>shift</b>?
            </Paragraph>
            <div data-pressed="{shift}">
                <Keyboard layoutName="shift" {...KeyboardProps}/>
            </div>
            <Paragraph>
                sadly, most of these characters are pretty important. you know where the important characters don't live?
            </Paragraph>
            <Paragraph>
                <b>option</b>!
            </Paragraph>
            <div data-pressed="{option}">
                <Keyboard layoutName={isCute ? "cuteOption" : (isSuperCute ? "superCuteOption" : "option")} {...KeyboardProps}/>
            </div>
            <Paragraph>
                now, some of these do pull their weight; ≤ and ≥, maybe you need a π here and there, but when is the last time you needed to type ª?
            </Paragraph>
            <Paragraph>
                to be fair, option isn't too egregious...the actually useless characters are nested under <b>option+shift</b>.
            </Paragraph>
            <div data-pressed={["{option}", "{shift}"]}>
                <Keyboard layoutName={isCute ? "cuteOptionShift" : (isSuperCute ? "superCuteOptionShift" : "optionShift")} {...KeyboardProps}/>
            </div>
            <Paragraph>
                if somebody wanted to add a bunch of new keys to a keyboard, seems like rooting out the bad s is a pretty safe start.
            </Paragraph>
            <Paragraph>
                at least, that's what 8th grade me did!
            </Paragraph>
            <Title level={2}>
                cute!
            </Title>
            <Paragraph>
                my primary keylayout for many years was <b>cute</b>, which mainly just gutted option+shift and tossed in a bunch of emotes: your [•-•'']s, your [•⥐•]s, and certainly your [•-•]7s.
            </Paragraph>
            <Paragraph><b>behold: <Checkbox onChange={(e) => {
                setCute(e.target.checked);
                setSuperCute(false);
            }}/> activate cuteness?</b></Paragraph>
            <Paragraph>
                if you're looking at the above layout and thinking, well gosh, this doesn't feel very systematic...yeah.
            </Paragraph>
            <ul>
                <li>i had a bunch of blank characters, since despite my emote love, i couldn't fill the space [•-•]ፓ</li>
                <li>i was starting to use (gasp) emojis (🕶), i wanted more math symbols, maybe some greek letters?</li>
                <li>i was in, like, 8th grade, so you've got some weird cruft in there like :thinking: and <a href="https://www.youtube.com/watch?v=2uxr9RXKByA" target="_blank" rel="noopener noreferrer">ゴゴゴゴ</a></li>
            </ul>
            <Paragraph>
                how do we fix this problem? a keyboard only has so many states...right?
            </Paragraph>
            <Title level={2}>
                super cute! (or, how i learned to stop worrying and love dead key states)
            </Title>
            <Paragraph>
                it is true that there are only so many "standard" modifier keys...but why do they get to have all the fun?
            </Paragraph>
            <Paragraph>
                dead key states are a mechanism to add state machines to a keyboard layout, and are primarily used to encode accents, or by dorks to perform evil keyboard wizardry
            </Paragraph>
            <Paragraph>
                exhibit a: you have this really funky double dot accent, ¨, and you want it to fuse with your favorite vowels. you're really telling me i need to have ä, ö, ï, ... all on my keyboard?
            </Paragraph>
            <Paragraph>
                no, silly! when you type "¨" (option+u on the default mac keyboard) , you enter the umlaut dead key state. pressing any vowel outputs the combined pair; any other character is not a valid output for the state, so is printed normally.
            </Paragraph>
            <Paragraph>
                this is actually the reason that macos keylayouts may appear to have duplicate accent characters: the "option" version triggers the dead key state, whereas the "option+shift" version simply outputs the cap character.
            </Paragraph>
            <Paragraph>
                there's also no reason a dead key state needs to be related to its cap character; we as language users expect ˜ + n to output ñ, but it could just as easily produce 🆒🆗🆕🆓. see where i'm going with this?
            </Paragraph>
            <Paragraph>
                my current keyboard layout has about 6 dead key states for various uses (emotes, 2x emoji, math, greek, linguistics). it's not necessarily the <i>most</i> elegant solution (i often forget where i put specific emoji, for instance), but it does mean i can have effectively as many keyboards as i want in a single layout. oh, and you can nest dead key states, if you want to commit unspeakable acts of horror.
            </Paragraph>
            <Paragraph>
                representing all of <b>super cute</b>'s dead key states here is a little more tedious than i care to do, and honestly it's easier to just play around with it by using it. so...
            </Paragraph>
            <Paragraph>
                <a href="https://github.com/Nathansbud/Custom-Keyboards">go download my keyboard layouts</a>! make them better! make your own cool ones and send them to me! go nuts!
            </Paragraph>
            <Paragraph>
                as i mentioned above, ukelele is a fantastic tool to create and modify keylayout files. be warned, though: it doesn't play super nice with multikey outputs, and tends to crash when dealing with long strings. since keylayout files are just fancy xml, you can hack on them in a text editor if need be. have fun!
            </Paragraph>
            <Paragraph>
                📈
            </Paragraph>
        </>
    )
}

export default KeyboardPage;
